.login-form{
    padding: 30px 25px;
    color: $primary;
    .input-group {
        position: relative;
        margin: 40px 0 20px;
      }

      .btn-primary{
        background-color: $menu-color !important;
        color: $white;
        margin-top: 15px;
        border: none;
      }
      .btn-secondary{
          background-color: $white !important;
          border: 1px solid $light-ash !important;
      }
      .registration{
          margin-top: 30px !important;
          color: rgb(108, 108, 196) !important;
          cursor: pointer !important;
          font-weight: 500;
          font-size: 0.9rem;
      }
      .forgot-pswd{
        color: rgb(108, 108, 196) !important;
        cursor: pointer !important;
        font-weight: 500;
        font-size: 0.9rem;
      }
      .input-group-text{
          background-color: transparent !important;
          border: none;
          border-bottom: 1px solid #757575;
      }

      .login-sub-text1{
        color: $light-ash;
        font-size: 0.9rem;
    }
      
      input {
        font-size: 1rem;
        padding: 10px 10px 10px 5px;
        display: block;
        width: 300px;
        border: none;
        border-bottom: 1px solid #757575;
        color: $form-label-color;
      }
      
      input:focus {
        outline: none;
      }
      
      label {
        color: #999;
        font-size: 1rem;
        font-weight: normal;
        position: absolute;
        pointer-events: none;
        left: 5px;
        top: 10px;
        transition: 0.2s ease all;
        -moz-transition: 0.2s ease all;
        -webkit-transition: 0.2s ease all;
      }
      
      input:focus ~ label,
      input:valid ~ label {
        top: -20px;
        font-size: 1rem;
        color: $primary;
      }
      
      .bar {
        position: relative;
        display:block;
        width:315px;
      }
      
      .bar:before,
      .bar:after {
        content: '';
        height: 2px;
        width: 0;
        bottom: 1px;
        position: absolute;
        background: $primary;
        transition: 0.2s ease all;
        -moz-transition: 0.2s ease all;
        -webkit-transition: 0.2s ease all;
      }
      
      .bar:before {
        left: 50%;
      }
      
      .bar:after {
        right: 50%;
      }
      
      input:focus ~ .bar:before,
      input:focus ~ .bar:after {
        width: 50%;
      }
      
      .highlight {
        position: absolute;
        height: 60%;
        width: 100px;
        top: 25%;
        left: 0;
        pointer-events: none;
        opacity: 0.5;
      }
      
      input:focus ~ .highlight {
        -webkit-animation: inputHighlighter 0.3s ease;
        -moz-animation: inputHighlighter 0.3s ease;
        animation: inputHighlighter 0.3s ease;
      }

      input:focus{
          border: none;
      }
      
      /* animations */
      @-webkit-keyframes inputHighlighter {
        from { background: $primary; }
        to   { width: 0; background: transparent; }
      }
      @-moz-keyframes inputHighlighter {
        from { background: $primary; }
        to   { width: 0; background: transparent; }
      }
      @keyframes inputHighlighter {
        from { background: $primary; }
        to   { width: 0; background: transparent; }
      }
}

.login-container-fluid{
    padding: 0px;
}

.bg-login{
    min-height: 65vh;
}

.login-card{
    float: none;
    margin: auto;
    box-shadow: 0 1px 15px rgba(0,0,0,.04), 0 1px 6px rgba(0,0,0,.04);
    margin-top: 5%;
    margin-bottom: 5%;
}

.login-sub-text{
    color: $light-ash;
    font-size: 0.9rem;
    padding-bottom: 45px;
}

.detail-part{
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    padding: 40px 40px;
    padding-top: 25px;
    background-size: 100%;
    img{
        height: 220px;
        width: 250px;
    }
}

.logn-part{
    padding: 30px 50px;
    padding-top: 35px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    // font-family: $theme-font;
    // border-left: 1px solid $md-border;
}

@media  (max-width:600px) {
  .logn-part{
      border-left:0px solid !important;
  }
  
}